<template>
  <div class="page page--verify">
    <section class="section" id="sec-1" data-sec="1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="text tc-blue fs-xl-20 fw-500 ta-center ta-md-left mb-3 mb-md-4">{{ cc.sec1.text[0] }}</p>
          </div>
          <div class="col-12 col-lg-6 pe-lg-5 mb-5 mb-lg-0">
            <h1 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 ta-center ta-md-left mb-3 mb-md-4">{{ cc.sec1.title }}</h1>
            <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-4 mb-md-5">{{ cc.sec1.text[1] }}</p>
            <div class="img img--verify-sec1">
              <img src="/img/pages/accounts/verify/sec1/img-2.svg" alt="">
              <img src="/img/pages/accounts/verify/sec1/img-1.png" alt="">
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <p class="text tc-silver fs-14 fs-sm-20 fw-400 lh-20 lh-md-17 ta-center ta-md-left mb-3">{{ cc.sec1.text[2] }}</p>
            <div class="wrapper d-flex align-items-center">
              <ul class="list list--icons">
                <li v-for="(item, i) in cc.sec1.list" :key="'choose-list-' + i">
                  <div class="ico ico--circle me-4" :class="'ico--circle-' + item.theme">
                    <img :src="'/img/icons/ico-' + item.key + '-' + item.theme + '.svg'" :alt="item.key">
                  </div>
                  <div>
                    <h2 class="title fs-14 fs-sm-16 fs-md-20 fw-600 mb-3">{{ item.title }}</h2>
                    <template v-if="item.text.length > 1">
                      <p class="text tc-silver fs-14 fs-md-16 fw-400 lh-20">
                        {{ item.text[0] }} <a :href="item.link.to" :target="item.link.target" class="link" :class="'tc-' + item.theme">{{ item.link.text }}</a> {{ item.text[1] }}
                      </p>
                    </template>
                    <template v-else>
                      <p class="text tc-silver fs-14 fs-md-16 fw-400 lh-20">
                        {{ item.text[0] }}
                        <template v-if="'link' in item">
                          <a :href="item.link.to" :target="item.link.target" class="link" :class="'tc-' + item.theme">{{ item.link.text }}</a>
                        </template>
                      </p>
                    </template>
                    <template v-if="'btn' in item">
                      <a :href="item.btn.to" :target="item.btn.target" class="link link--arrow link--td-none mt-4">
                        <span class="link fs-16 fs-md-18 fs-xl-20" :class="'tc-' + item.theme">{{ item.btn.text }}</span>
                        <i class="ico ico--ui-arrow">
                          <img :src="'/img/icons/ui/ico-any-arrow-right-' + item.theme + '.svg'" alt="arrow">
                        </i>
                      </a>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-2" data-sec="2">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4 mb-4 mb-lg-0" v-for="(item, i) in cc.sec2.boxes" :key="'docs-item-' + i" :style="i === 1 ? 'position: relative; z-index: 2' : null">
            <div class="wrapper pt-lg-4">
              <div class="d-flex flex-row align-items-center mb-3 mb-md-4" style="position: relative; z-index: 2">
                <p class="text text--boxed text--boxed-blue tc-blue fs-16 fw-500">{{ item.title }}</p>
                <template v-if="i === 1">
                  <div class="hint">
                    <div class="hint__head">
                      <img src="/img/icons/ui/ico-info.svg" alt="i" />
                    </div>
                    <div class="hint__body">
                      <div class="hint__body-content">
                        <img src="/img/pages/accounts/verify/sec2/img-1.png" alt="example" />
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div style="position: relative; z-index: 1">
                <p class="text tc-silver fs-14 fs-sm-16 fw-400 lh-20 mb-3 mb-md-4 pe-xl-4">{{ item.text }}</p>
                <ul class="list list--arrow">
                  <li v-for="(li, x) in item.list" :key="'docs-item-list-' + x">
                    <div class="ico ico--arrow me-4" :class="'ico--arrow-' + li.theme">
                      <img :src="'/img/icons/ui/ico-any-check-' + li.theme + '.svg'" :alt="li.key">
                    </div>
                    <p class="text tc-silver fs-14 fs-sm-16 fw-400 lh-17">{{ li.text }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4" style="position: relative; z-index: 1">
            <div class="block block--documents">
              <p class="text tc-white fw-500 mb-3 mb-md-4">{{ cc.sec2.box.text }}</p>
              <comp-list-arrow :item="cc.sec2.box.list" :tColor="'white'"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="sec-3" data-sec="3">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12">
            <p class="text tc-blue fs-18 fs-md-20 fw-500">{{ cc.sec3.text[0] }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 pe-lg-5 mb-5 mb-lg-0">
            <h2 class="title fs-30 fs-sm-50 fs-md-52 fs-xl-58 fw-600 lh-12 mb-3 mb-md-4">{{ cc.sec3.title }}</h2>
            <p class="text text tc-silver fs-14 fs-sm-16 fs-md-20 fw-400 lh-20 mb-4 mb-md-5">{{ cc.sec3.text[1] }}</p>
            <div class="img img--verify-sec3">
              <img src="/img/pages/accounts/verify/sec3/img-2.svg" alt="">
              <img src="/img/pages/accounts/verify/sec3/img-1.png" alt="">
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div v-for="(item, i) in cc.sec3.boxes" :key="'add-docs-item-' + i" :class="{'mb-4 mb-md-5': i === 0}">
              <p class="text text--boxed text--boxed-blue tc-blue fs-14 fw-500 mb-3 mb-md-4">{{ item.title }}</p>
              <p class="text tc-silver fs-14 fs-sm-16 fs-md-20 fw-400 lh-20 mb-3 mb-md-4 pe-xl-4">{{ item.text }}</p>
              <div class="row">
                <div class="col-12" :class="{'col-lg-6': ('list2' in item)}">
                  <comp-list-arrow :item="item.list1" :tColor="'silver'" />
                </div>
                <template v-if="('list2' in item)">
                  <div class="col-12 col-lg-6">
                    <comp-list-arrow :item="item.list2" :tColor="'silver'" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--gray-half" id="sec-4" data-sec="4">
      <comp-sample :title="cc.sec4.title" :text="cc.sec4.text" :img="'/img/pages/accounts/verify/sec4/img-1.png'" :imgmob="'/img/pages/accounts/verify/sec4/mob/img-1.png'" :isAbstract="false" :theme="'blue'"/>
    </section>
    <section class="section section--gray" id="sec-5" data-sec="5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="block block--client">
              <div class="row">
                <div class="col-12 col-lg-5 mb-4 mb-lg-0">
                  <p class="text tc-blue fs-14 fs-md-16 fw-500 lh-17">{{ cc.sec5.text }}</p>
                </div>
                <div class="col-12 col-lg-7">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <comp-list-arrow :item="cc.sec5.list1" :tColor="'silver'" />
                    </div>
                    <div class="col-12 col-lg-6">
                      <comp-list-arrow :item="cc.sec5.list2" :tColor="'silver'" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CompSample from '@/components/Sample'
import CompListArrow from '@/components/lists/ListArrow'
export default {
  name: 'Verify',
  components: {
    CompSample, CompListArrow
  },
  computed: {
    cc () {
      return this.$t('pages.accounts.verify')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.accounts.child.verify')
    return {
      titleTemplate: '%s | ' + meta.name,
      meta: [
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>
